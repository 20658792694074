.internal-maindiv h2
{
    margin-top: 2rem; 
    margin-bottom: 1rem;
    font-family: "Rubik", sans-serif;
   font-size: 30px;
}

.internal-div
{
    /* background: #c7ffed67; */
    background-color: rgba(35,171,229,.15); 
    /* margin-top: 1rem; */
    width: 100%;
}
.internal-div h2
{
    margin-top: 0;
    padding-top: 2rem; 
    /* margin-bottom: 2rem; */
}
.select-opt{
    margin-bottom :1rem;
}
.emp-row
{
    /* border: 2px solid; */
    justify-content: center;
    text-align: center;
    width:100%;
}
.emp-topm
{
    margin-top: 2rem;
}
.emp-col1
{
    /* border: 2px solid red; */
    height: 250px;
    /* margin-left: 1rem; */
}
.emp-col1 img
{
    width: 80%;
    height: 250px;
    object-fit: cover;
    /* padding: 10px; */
}
.emp-col2
{
    /* border: 2px solid red; */
    height: 250px;
    justify-content: left;
    text-align: left;
    /* margin-bottom: 2.5rem; */
}
.col-buttom
{
    margin-bottom: 2rem;
}
.col-text2
{
    text-align: right;
}
.emp-col2 h5
{
    padding-top: 4rem;
    font-size: 22px;
}
.emp-col2 p
{
    font-size: 15px;
}

.openposition-heading
{
    text-align: center;
    padding-top: 1rem; 
    font-family: "Rubik", sans-serif;
   font-size: 30px;
}
.scrollbar-outerdiv{
     /* border: 1px solid;  */
    max-height: 450px; 
    
    overflow: hidden;
    overflow-y: auto;
   
    margin-left: 1rem;
    margin-right: 1rem;

} 
.scrollbar-internaljob{
     /* border: 1px solid red; */
    max-height: 325px;
    overflow: hidden;
    overflow-y: auto; 
    overflow-y: hidden;   
    /* margin-left: 1rem;
    margin-right: 1rem;
    */
}
input:checked+.scrollbar-internaljob{
    max-height: 800rem;
    
} 
/* input[type="checkbox"]{
    display: none;
}   */
/* label{
    color: blue;
    text-decoration: underline;
    margin-top: 0.5rem;
    cursor: pointer;
    display: inline-block;
} */
.internal-viewmore:after{
    content: "More Jobs..";
   
}
input:checked~.internal-viewmore:after{
    content: "Less Jobs..";
} 
.internal-viewmore{
    font-size: 16px;
    font-weight: 500;
   color: white;
   margin-bottom: 1rem;
   cursor: pointer;
   float: left;
   background-color: #5d99e7;
   padding: 10px;
   margin-left: 7rem;
   margin-top: 0.5rem;
}
.openposition-row
{
   
    margin-top: 1rem;
    margin-bottom: 1.5rem;
     justify-content: center;
     width:100%;
   
}
.openposition-col
{
    /* border: 1px solid; */
    height: 282px;
    width: 19%;
    margin-left: 15px;
    margin-right: 15px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.openposition-col2{
    margin-top: 3rem;
}
.openposition-col:hover
{
    background-color: rgba(128, 128, 128, 0.226);
   
}
.heading-rowbg
{
    background-color:#8EA9DB;
    height: 60px;
    
}
.secondheading-row{
    margin-top: 1rem;
}
.heading-rowbg h6
{
    text-align: left;
    color: white;
    padding: 20px 0 0 18px;
    font-size: 18px;
}

.text-col h4
{
    text-align: left;
    height: 10%;
    padding: 30px 0 100px 19px;
    font-size: 16px; 
    font-weight: 450;
    /* border: 1px solid red; */
}
.footer-row.row
{   
    padding-top:1.9rem ;
   
}
.loc-text
{
    /* border: 1px solid; */
    /* padding-left: 12px; */
    text-align: left;
    margin-left: 10px;
    
}
.loc-text p
{
    padding-left: 5px;
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
    width: 90%;
}
.icon-text p
{
    font-size: 22px;
    margin-top: 5px;
    color: rgba(0, 0, 0, 0.788);
    
}
.text-even p:hover{
    transition-timing-function: ease-in;
    transition: 0.25s;
    transform: translateY(5%);
    cursor: pointer;
    color: red;
}
.text-odd p:hover{
    transition-timing-function: ease-in;
    transition: 0.25s;
    transform: translateY(5%);
    cursor: pointer;
    color: green;
}

.row {
    --bs-gutter-x: 0rem;
}



@media only screen and (max-width: 1101px){
    .footer-row.row{
        padding-top: 0;
    }
    .row {
        --bs-gutter-x: 0rem;
    } 
    .text-col h4 {
       
        padding: 30px 0 125px 19px;
        /* font-size: 20px; */
        /* font-weight: 450; */
        /* border: 1px solid red; */
    }
    .icon-text p{
        margin-top: 15px;
    }
    .internal-viewmore{
        margin-left: 4rem;
    }
}


@media only screen and (max-width: 950px){
    .openposition-row{
        display: grid;
        grid-template-columns: 30% 30% 30%;
 
         gap:2rem;
         width:90%;
         margin:1rem auto;
        justify-content: center;
     }
     .openposition-col{
        width:100%;
        margin: 0;
    }
    .scrollbar-internaljob{
        max-height: 320px;
    }
}
@media only screen and (max-width: 850px){
    .internal-viewmore{
        margin-left: 3rem;
    }
}
@media only screen and (max-width: 700px){
    .internal-viewmore{
        margin-left: 2rem;
    }
}
@media only screen and (max-width: 650px){
    .openposition-row{
        grid-template-columns: 50% 50%;
        width:80%;
    }
    .scrollbar-outerdiv{
        max-height: 50rem;
    }
    .scrollbar-internaljob{
        max-height: 39.5rem;
    }
    .internal-viewmore{
        margin-left: 3rem;
    }
}
@media only screen and (max-width: 575px){
    .loc-text p
    {
        margin-top: 0;
        margin-bottom: 0px;
       
    }
    .icon-text p{
        margin-top: 0;
    }
    .text-col h4{
        padding: 30px 0 112px 19px;
    }
    .col-buttom{
        margin-bottom: 0;
    }
}
@media only screen and (max-width: 576px){
    .emp-row:nth-child(2){
        flex-direction: column-reverse;
    }
    .emp-col2{
        height: 100%;
        padding: 1rem 0.6rem;
    }
    .emp-col2 h5,.emp-col2 p{
        text-align: center;
        padding:0 0.5rem;
    }
    
    .openposition-row{
       display: grid;
       grid-template-columns: 50% 50%;

        gap:2rem;
        width:85%;
        margin:1rem auto;
    }
    .openposition-col{
        width:100%;
        margin: 0;
    }
    .text-col h4{
        padding: 30px 0 125px 19px;
    }
   
    .loc-text p
    {
        font-size: 13px;
        width: 80%;
        padding-left: 10px;
    }
    
}




@media only screen and (max-width: 525px){
    .loc-text p
    {
        padding-left: 0px;
        margin-top: 10px;
        width: 100%;
    }
    .openposition-row{
         width:93%;
         gap: 1rem;
         margin: 1rem 1rem;
     }
     .scrollbar-internaljob{
        max-height: 38.2rem;
    }
}

@media only screen and (max-width: 800px){
    .scroll-jobdiv{
        margin-left: 1rem;
        margin-right: 1rem;
    }
    .text-col h4{
        font-size: 15px;
    }
    .text-col p{
        font-size: 13px;
    }
    .icon-text p{
        font-size: 18px;
    }
}

@media only screen and (max-width: 490px){
    .openposition-row{
        display: grid;
        grid-template-columns: 100%;
 
         gap:2rem;
         width:75%;
         margin:1rem auto;
     }
     .openposition-col{
         width:100%;
         margin: 0;
         height: 300px;
     }
     .loc-text{
        width: 100%;
     }
     .loc-text p{
        width: 80%;
        font-size: 15px;
        padding-left:13px;
     }
     .footer-row.row{
        padding-top: 0.5rem;
        display: grid;
        grid-template-columns: 80% 15%;
     }
     .text-col h4{
        /* font-size: 18px; */
        padding-left: 20px;
     }
     .openposition-col{
        width: 95%;
        margin: auto;
     }

     .icon-text p
{
    
    margin-top: 12px;
   
    
}

.scrollbar-outerdiv{
    max-height: 95rem;
}
.scrollbar-internaljob{
    max-height: 83rem;
}
.internal-viewmore{
    margin-left: 0rem;
    float: none;
    margin-top: 1rem;
}
}

@media only screen and (max-width: 451px){
    .emp-col2 p br{
        display: none;
    }
}
@media only screen and (max-width: 430px){
    .loc-text p{
        /* font-size: 1rem;  */
        padding-left: 10px;
        /* width: 95%; */
        /* margin-top: -15px; */
     }
     
     
}
@media only screen and (max-width: 400px){
    
    /* .text-col h4{
        font-size: 16px
    }
    .loc-text p{
        font-size: 13.5px;
    } */
    .openposition-col{
        width: 100%;
    }
}
@media only screen and (max-width: 359px){
    .emp-col2{
        padding: 1rem 0.4rem;
    }
    .emp-col2 h5,.emp-col2 p{
        padding:0 0.4rem;
    } 
}
@media screen and (min-width: 1400px) and (max-width: 1500px){
    .internal-viewmore{
        margin-left: 6rem;
    }
  } 
  @media screen and (min-width: 1500px) and (max-width: 1600px){
    .internal-viewmore{
        margin-left: 7rem;
    }
  }
  @media screen and (min-width: 1600px) and (max-width: 1735px){
    .internal-viewmore{
        margin-left: 12rem;
    }
  }
  @media screen and (min-width: 1735px) and (max-width: 1799px){
    .internal-viewmore{
        margin-left: 13rem;
    }
  }
  @media screen and (min-width: 1800px) and (max-width: 1900px){
    .internal-viewmore{
        margin-left: 18rem;
    }
  }
  @media screen and (min-width: 1900px) and (max-width: 2024px){
    .internal-viewmore{
        margin-left: 20rem;
    }
  }
  @media screen and (min-width: 1600px) and (max-width: 1800px){
    .scrollbar-internaljob{
        width: 90%;
        margin: auto;
    }
    
  }
  @media screen and (min-width: 1800px) and (max-width: 2024px){
    .scrollbar-internaljob{
        width: 80%;
        margin: auto;
    }
    
  }